<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="350"
  >
    <v-card>
      <v-system-bar
        window
        color="white"
      >
        <v-spacer></v-spacer>
        <v-btn small icon color="primary" @click="$emit('close')">
          <v-icon color="primary">mdi-close</v-icon>
        </v-btn>
      </v-system-bar>

      <v-card-text class="black--text d-flex flex-column text-center justify-center pt-1 pb-8">
        <div class="mb-2">
          <v-icon large color="warning">mdi-information-outline</v-icon>
        </div>
        <div>
          <span class="pt-1" v-html="message"></span>
        </div>
        <div class="mt-4">
          <v-btn
            depressed
            outlined
            small
            color="primary"
            @click="$emit('go')"
          >
            Ok
            <v-icon
              color="orange darken-4"
              right
            >
              mdi-open-in-new
            </v-icon>
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: 'AlertDialog',
  props: {
    dialog: Boolean,
    message: String,
  },
}
</script>