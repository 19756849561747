<template>
  <v-container class="fill-heigt pt-0" fluid>
    <v-row>
      <v-col cols="12" md="12">
        <v-btn icon color="primary" @click="back()">
          <v-icon>mdi-arrow-left</v-icon>
        </v-btn>
      </v-col>
      <v-col cols="12" md="12">
        <!-- <pre>
        {{vacancy}} 
      </pre> -->
        <v-skeleton-loader
          v-bind="attrs"
          type="list-item-avatar, divider, list-item-three-line, card-heading, image, actions"
          v-if="loading"
        ></v-skeleton-loader>
        <vacancy-details-card
          :vacancy="vacancy"
          :apply_cand="apply_cand"
          @apply="apply"
          v-else
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { ADD_VIEWS_VACANCY } from "../graphql/Mutation";
import { GET_VACANCY_BY_ID_QUERY } from "./../graphql/Query";
import VacancyDetailsCard from "../components/VacancyDetailsCard.vue";
import { mapGetters } from "vuex";
export default {
  name: "VacancyDetails",
  components: { VacancyDetailsCard },
  data: () => ({
    skipQuery: true,
    vacancy: {},
    vacancyId: undefined,
    apply_cand: false,
    loading: true,
  }),
  apollo: {
    vacancy: {
      query: GET_VACANCY_BY_ID_QUERY,
      variables() {
        return {
          id: this.vacancyId,
          fromCandidate: true,
        };
      },
      skip() {
        return this.skipQuery;
      },
      result({ data, loading }) {
        if (!loading) {
          this.loading = false;
          this.vacancy = data.vacancy;
          this.vacancy.candidacies.forEach((element) => {
            if (element.candidate.id === this.currentUser.candidate.id) {
              this.apply_cand = true;
            } else {
              this.apply_cand = this.$route.query.done;
            }
          });
          // localStorage.removeItem("loginUrl");
        }
      },
    },
  },
  created() {
    if (localStorage.getItem("loginUrl")) {
      localStorage.removeItem("loginUrl");
    }
    this.vacancyId = this.$route.params.id;
    this.addViews();
    this.getVacancy();
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
  },
  methods: {
    addViews() {
      this.$apollo.mutate({
        mutation: ADD_VIEWS_VACANCY,
        variables: {
          id: this.$route.params.id,
        },
      });
    },
    back() {
      this.$router.push({
        path: `/candidate`,
        query: { apply_cand: this.apply_cand ? this.$route.params.id : false },
      });
    },
    apply() {
      this.apply_cand = this.$route.params.id;
    },
    async getVacancy() {
      this.$apollo.queries.vacancy.skip = false;
      await this.$apollo.queries.vacancy.refetch();
    },
  },
};
</script>
