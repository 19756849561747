<template>
  <v-card class="pb-0 px-0">
    <v-card-title class="justify-center">
      <span class="headline">{{ $t(test.area) }}</span>
    </v-card-title>
    <v-card-text>
      <v-row>
        <!-- <pre>
          {{ test.questions[position].file }}
        </pre> -->
        <v-col v-show="test.questions[position].picture"
          ><v-img
            :src="`${apiUrl}/images/test/${test.questions[position].picture}`"
            contain
          ></v-img>
        </v-col>

        <v-col cols="12" md="12" lg="12" xlg="12">
          <div class="text-justify">
            <v-textarea
              :value="test.questions[position].question"
              auto-grow
              dense
              hide-details
              flat
              readonly
              solo
            />
            <!-- <span class="headline"
              >{{ test.questions[position].question }}
            </span> -->
          </div>
          <div class="text-center">
            <v-btn
              small
              dense
              color="error"
              v-if="test.questions[position].file"
            >
              <v-icon class="ma-1" small>mdi-cloud-download</v-icon>
              &nbsp;&nbsp;<a
                :href="`${apiUrl}/files/test/${test.questions[position].file}`"
                target="_blank"
                class="text-decoration-none white--text"
                >{{ $t("download_file") }}</a
              ></v-btn
            >
            <UploadFile
              :pos="position"
              @upload="upload"
              v-if="test.questions[position].file"
            />
          </div>
        </v-col>
        <!-- kkkkkk -->
        <v-col cols="12" md="12" lg="12" xlg="12" class="pa-0">
          <v-row v-if="test.questions[position].alternatives[0].answer">
            <v-col
              cols="12"
              md="12"
              class="pa-1"
              v-for="(alternative, index) in questions[position].alternatives"
              :key="index"
            >
              <v-card
                class=""
                max-width="800"
                flat
                fluid
                style="border: 1px solid #f7a400; border-radius: 5px"
                @click="selectAnswer(index)"
                :style="{
                  background: alternative.response ? '#f7a400' : '',
                  color: alternative.response ? '#fff' : '#fff',
                }"
                v-show="!test.questions[position].openQuestion"
              >
                <v-card-text style="padding-top: -20px">
                  <div
                    class="flex-grow-1 text-center"
                    :style="{ color: alternative.response ? '#fff' : '#000' }"
                  >
                    {{ alternative.answer }}
                  </div>
                </v-card-text>
              </v-card>
              <v-row
                class="ma-0"
                v-show="test.questions[position].openQuestion"
              >
                <v-col cols="12" md="12">
                  <span class="black--text text-body-1">
                    {{ alphabetArray[index].toLowerCase() + ")" }}
                    {{ alternative.answer }}
                  </span>
                </v-col>
                <v-col cols="12" md="12">
                  <v-textarea
                    v-model="alternative.textResponse"
                    :label="$t('answer')"
                    dense
                    hide-details
                    auto-grow
                    rows="1"
                  ></v-textarea> </v-col
              ></v-row>
            </v-col>
          </v-row>
          <v-col cols="12" md="12" class="pa-1" v-else>
            <v-textarea
              v-model="questions[position].answer"
              rows="3"
              outlined
              :label="$t('answer')"
              class="pt-5"
            ></v-textarea>
          </v-col>
        </v-col>
        <v-row class="pt-2 justify-center px-3" no-gutters>
          <v-col cols="12">
            <v-divider></v-divider>
          </v-col>
        </v-row>
      </v-row>
    </v-card-text>
    <v-card-actions>
      <v-row no-gutters>
        <v-col cols="12" class="px-6">
          <!-- {{ time }} {{ bufferValue }} -->
          <v-progress-linear
            v-model="time"
            :buffer-value="bufferValue"
            :color="progressColor"
          ></v-progress-linear>
        </v-col>
        <v-col cols="12" class="px-6">
          <v-btn
            v-if="position < questions.length - 1"
            block
            color="primary"
            tile
            @click="nextQuestion()"
            >{{ $t("next") }} {{ position + 1 }}/{{ questions.length }}</v-btn
          >
          <v-btn
            v-else
            block
            color="primary"
            tile
            @click="(dialog = false), nextQuestion()"
            >{{ $t("finish") }} {{ position + 1 }}/{{ questions.length }}</v-btn
          >
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { API_URL } from "@/api";
import UploadFile from "./UploadFile.vue";
export default {
  components: {
    UploadFile,
  },
  name: "Quiz",
  props: {
    test: Object,
    start: Boolean,
  },
  data: () => ({
    apiUrl: API_URL,
    bufferValue: 100,
    interval: 0,
    position: 0,
    questions: [
      {
        answer: "",
        question: "",
        alternatives: [
          {
            answer: "",
            value: false,
            response: false,
          },
        ],
        totalCorrect: 0,
        status: false,
        picture: undefined,
      },
    ],
    resultTest: undefined,
    time: 0,
    clicked: false,
    alphabetArray: Array.from({ length: 26 }, (_, index) =>
      String.fromCharCode(65 + index)
    ),
  }),
  watch: {
    time(val) {
      if (val > 60) {
        this.progressColor = "red";
      } else {
        this.progressColor = "primary";
      }
      if (val < 100) return;

      if (this.position >= this.test.questions.length - 1) {
        clearInterval(this.interval);
      }
      this.nextQuestion();
    },
    start(val) {
      if (val) {
        this.startBuffer();
      }
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
  created() {
    this.progressColor = "primary";
    this.questions = this.test.questions.map((q) => ({
      ...q,
      alternatives: q.alternatives.map((a) => ({ ...a, response: false })),
      answer: "",
    }));
    this.startBuffer();
  },
  methods: {
    getDuraction(test) {
      const t = test.duraction;

      let ms =
        Number(t.split(":")[0]) * 60 * 60 * 1000 +
        Number(t.split(":")[1]) * 60 * 1000;

      return ms / test.questions.length / 100;
    },
    nextQuestion() {
      if (this.position < this.test.questions.length - 1) {
        this.position += 1;
      } else {
        clearInterval(this.interval);
        this.calculateFinalResult();
        this.$emit("finish", {
          testId: this.test.id,
          questions: this.questions,
          result: this.resultTest,
        });
      }
      this.time = 0;
    },
    selectAnswer(pos) {
      let value = this.questions[this.position].alternatives[pos].response;
      if (this.questions[this.position].totalCorrect == 1) {
        for (
          let index = 0;
          index < this.questions[this.position].alternatives.length;
          index++
        ) {
          this.questions[this.position].alternatives[index].response = false;
        }
      }
      this.questions[this.position].alternatives[pos].response = value
        ? false
        : true;
    },
    startBuffer() {
      clearInterval(this.interval);

      this.interval = setInterval(() => {
        this.time += 1;
      }, this.getDuraction(this.test));
    },
    calculateFinalResult() {
      let result = 0;
      this.questions.forEach((q) => {
        let total = 0;
        let weight = 0;
        for (let index = 0; index < q.alternatives.length; index++) {
          if (q.alternatives[index].value && q.alternatives[index].response) {
            total += 1;
          }
        }
        // if (total == q.totalCorrect) {
        //   result = result + q.percentage;
        // }
        weight = q.percentage / q.totalCorrect;
        result += weight * total;
      });
      this.resultTest = result;
    },
    upload(files, pos) {
      this.$emit("upload", files, pos);
    },
  },
};
</script>
