<template>
  <v-container>
    <v-btn depressed color="primary_text">
      <form ref="myForm">
        <input
          style="opacity: 0; cursor: pointer; margin-top: -8%; position: absolute; right: -1%;"
          class="input-file"
          id="my-file"
          type="file"
          @change="uploadFile"
          ref="file"
        />
      </form>
      <v-icon small color="black">mdi-plus</v-icon>
      <span class="text-body-2 black--text text-capitalize"
        >{{ $t("attach_files") }} [{{ files.length }}]</span
      >
      <v-btn x-small fab v-show="check" color="success" class="ml-3">
        <v-icon x-small>mdi-check</v-icon>
      </v-btn>
      <v-btn x-small fab v-show="no_doc" color="error" class="ml-3">
        <v-icon x-small>mdi-close</v-icon>
      </v-btn>
    </v-btn>
    <v-card v-show="files.length">
      <v-list>
        <v-list-item-group>
          <template v-for="(filename, index) in files">
            <v-list-item :key="index">
              <v-list-item-avatar size="20">
                <v-icon x-small class="grey lighten-1" dark> mdi-file </v-icon>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title>{{ filename.name }}</v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-btn x-small icon @click="removeTrainingFile(index)">
                  <v-icon color="error">mdi-close-circle</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider v-if="index < files.length - 1" :key="index"></v-divider>
          </template>
        </v-list-item-group>
      </v-list>
    </v-card>
    <WhirelabSnackbar
      :snackbar="snackbar"
      :timeout="timeout"
      :text="snackbar_test"
      :close="closeSnackbar"
      :color="'error'"
    />
  </v-container>
</template>

<script>
import WhirelabSnackbar from "./WhirelabSnackbar.vue";
export default {
  name: "UploadTrainingFile",
  components: {
    WhirelabSnackbar,
  },
  props: ["files", "index", "no_doc"],

  data: () => ({
    timeout: 900,
    snackbar: false,
    snackbar_test: "",
    check: false,
  }),

  methods: {
    closeSnackbar() {
      this.snackbar = false;
      // this.$router.push({
      //   path: `/entity/trainings/list`,
      // });
    },
    uploadFile() {
      let files = [];
      for (let index = 0; index < this.$refs.file.files.length; index++) {
        const element = this.$refs.file.files[index];
        console.log("file", element.size);
        if (element.size >= 1000000) {
          this.timeout = 2000;
          this.snackbar = true;
          this.snackbar_test = this.$t("The_document_cannot_exceed_2_MB");
          setTimeout(() => {
            this.snackbar = false;
            this.resetForm();
          }, this.timeout);
        } else {
          // files.push(element);
          files = [element];
          this.check = true;
          setTimeout(() => {
            this.check = false;
          }, this.timeout);
        }
      }

      this.$emit("upload", files, this.index);
    },

    resetForm() {
      this.$refs.myForm.reset();
      this.$refs.file.value = ""; // Resetting the value property for input file
    },
    removeTrainingFile(index) {
      // this.trainingFiles.splice(index, 1);
      this.$emit("remove", index, this.index);
    },
  },
};
</script>

<style scoped>
@import "./styles/input-file.css";
</style>
