<template>
  <v-card>
    <v-card-title class="justify-center">
      <span class="headline">{{ vacancy.entity.name }}</span>
    </v-card-title>
    <!-- <pre>{{test}}</pre> -->
    <v-card-text>
      <!-- <span
        class="text-justify black--text text-body"
        v-html="$t('suportExplainHi') + ' ' + currentUser.name + ','"
      ></span>
      <br /> -->
      <br />
      <span
        class="text-justify black--text text-subtitle-1"
        v-html="$t('info_stage_test').replace('DURATION', this.test.duraction)"
      ></span>
    </v-card-text>
    <v-card-actions>
      <v-row class="d-flex flex-row justify-end align-center mt-5 px-6 py-8">
        <v-spacer></v-spacer>

        <v-btn color="primary" @click="$emit('close')" dense text>{{
          $t("cancel")
        }}</v-btn>

        <v-btn type="submit" color="primary" sense @click="$emit('start')">{{
          $t("IntervieStart")
        }}</v-btn>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "InfoTest",
  props: {
    test: Object,
    vacancy: Object,
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
  },
};
</script>
