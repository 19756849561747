<template>
  <v-dialog
    v-model="dialog"
    persistent
    :max-width="status === 'finish' ? '400' : '800'"
    :fullscreen="isMobile"
  >
    <!-- {{ vacancy.documents }} -->
    <info-test
      :test="test"
      :vacancy="vacancy"
      @close="closeDialog()"
      @start="setStart()"
      v-if="status === 'stop'"
    />

    <info-vacancy
      :test="test"
      :vacancy="vacancy"
      @close="closeDialog()"
      @done="submitDoc"
      v-if="status === 'doc'"
    />
    <quiz
      :start="start"
      :test="test"
      @finish="finish"
      @upload="upload"
      v-else-if="status === 'start'"
    />

    <error-dialog
      :dialog="showError"
      :message="error"
      @close="closeErrorDialog"
    />
  </v-dialog>
</template>

<script>
import { API_URL } from "@/api";
import { SEND_STAGE_TEST_MUTATION } from "./../../../test/graphql/Mutation.service";
import { mapActions, mapGetters } from "vuex";
import { formatError } from "@/utils";
import InfoTest from "./InfoTest.vue";
import InfoVacancy from "./InfoVacancy.vue";
import Quiz from "./Quiz.vue";
import ErrorDialog from "./../../../../components/ErrorDialog.vue";
import { ADD_DIALOG } from "@/mixins/dialog";
import axios from "axios";
export default {
  components: { ErrorDialog, InfoTest, Quiz, InfoVacancy },
  name: "SolveTestDialog",
  mixins: [ADD_DIALOG],
  props: {
    dialog: Boolean,
    test: Object,
    vacancy: Object,
  },
  data: () => ({
    apiUrl: API_URL,
    status: "stop",
    start: false,
    isMobile: false,
    questions: [],
    isLoading: false,
    documents: [],
  }),
  created() {
    if (
      this.vacancy.documents.length &&
      this.vacancy.documents.find((dc) => dc.name === "")
    ) {
      this.status = "stop";
    } else {
      this.status = "doc";
    }

    this.start = false;
    if (/Mobi|Android/i.test(window.navigator.userAgent)) {
      this.isMobile = true;
      console.log("É um dispositivo móvel");
    } else {
      this.isMobile = false;
      console.log("Não é um dispositivo móvel");
    }
  },
  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
    }),
  },
  methods: {
    ...mapActions({
      updateTestState: "test/updateTest",
    }),
    closeErrorDialog(value) {
      this.showError = value;
      if (
        this.vacancy.documents.length &&
        this.vacancy.documents.find((dc) => dc.name === "")
      ) {
        this.status = "stop";
      } else {
        this.status = "doc";
      }
      this.start = false;
      this.$emit("close", this.documents);
    },
    submitDoc(documents) {
      // console.log(">>", documents);
      this.documents = documents;
      if (this.test) {
        this.status = "stop";
        this.start = false;
      } else {
        this.$emit("apply", documents);
        this.closeDialog();
      }
    },
    async finish(params) {
      try {
        let files = [];
        this.documents.forEach((element) => {
          element.files.forEach((file) => {
            files.push(file);
          });
        });
        this.status = "finish";
        this.start = false;
        await this.$emit("sendTest", this.status, this.isLoading);
        console.log(params);
        let test = this.test;
        test.stage = this.vacancy.stages[0].id;
        test.questions = params.questions;
        let stageTestsInput = {
          candidate: {
            id: this.currentUser.candidate.id,
            email: this.currentUser.candidate.email,
            name: this.currentUser.candidate.name,
            newCreated: false,
          },
          tests: [test],
          vacancyId: this.vacancy.id,
          entity: this.vacancy.entity.id,
          result: params.result,
          files,
        };
        console.log(stageTestsInput);
        await this.setQuestionFile(params.questions);

        console.log("enviar", params.questions);
        setTimeout(async () => {
          const { data } = await this.$apollo.mutate({
            mutation: SEND_STAGE_TEST_MUTATION,
            variables: { ...stageTestsInput },
          });
          console.log(data);
          this.$emit("updateApply");
        }, 5000);

        this.closeDialog();
      } catch (error) {
        this.error = formatError(error.message);
        this.showError = true;
      }
    },

    async setQuestionFile(questions) {
      await Promise.all(
        this.test.questions.map(async (question, key) => {
          if (question.doc) {
            console.log("doc", question.doc);
            question.fileResponse = await this.uploadQuestionFile(question.doc);
            questions[key].fileResponse = question.fileResponse;
            delete question.doc;
          }
          return question;
        })
      );
    },
    upload(file, pos) {
      file[file.length - 1].name;
      console.log(file[file.length - 1].name, pos);
      const lastfile = file[file.length - 1];
      this.test.questions[pos].doc = lastfile;
    },
    async uploadQuestionFile(file) {
      if (file) {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("id", this.currentUser.id);
        try {
          const response = await axios.post(
            `${API_URL}/test/upload`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          );
          // console.log(response.data.filename);
          return response.data.filename;
        } catch (error) {
          console.error(error); // Lida com erros durante o upload
        }
      }
    },
    closeDialog() {
      if (
        this.vacancy.documents.length &&
        this.vacancy.documents.find((dc) => dc.name === "")
      ) {
        this.status = "stop";
      } else {
        this.status = "doc";
      }
      this.start = false;
      this.dialog = false;
      this.$emit("close", this.documents);
    },
    setStart() {
      this.status = "start";
      this.start = true;
    },
  },
};
</script>
