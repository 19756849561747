export default {
  methods: {
    status (status) {
      if (status === "concluded")
        return this.$t('concluded');  
      else if (status === "ongoing" || status === "paused" || status === "runing")
        return this.$t('inprogress');
      else if (status === "pending")
        return this.$t('pending'); 
      else return this.$t('calledoff');  
    },
    statusColor (status) {
      if (status === "concluded") return "success";
      else if (status === "ongoing" || status === "runing") return "warning";
      else if (status === "pending") return "blue";
      else return "error";
    },
  }
}

export const statusVacancyMixins = {
  methods: {
    status (vacancy) {
      const dateStart = new Date(vacancy.publicationDate);
      const finalDate = new Date(vacancy.finalDate);
      const currentDate = new Date();
      const differenceInTimeS = dateStart.getTime() - currentDate.getTime();
      const differenceInTime = finalDate.getTime() - currentDate.getTime();
      const differenceInDaysS = differenceInTimeS / (1000 * 3600 * 24);
      const differenceInDays = differenceInTime / (1000 * 3600 * 24);
      if (differenceInDaysS > 0) {
        if (vacancy.status) {
          return { text: this.$t('waiting'), color: "warning" };
        } else {
          return { text: this.$t('pending'), color: "blue" };
        }
      }
      if (differenceInDays <= 0) {
        return { text: this.$t('expired'), color: "red" }
      } else if (differenceInDays > 0) {
        if (vacancy.status) {
          return { text: this.$t('published_'), color: "success" };
        } else {
          return { text: this.$t('pending'), color: "blue" };
        }
      }
    },
    status2 (vacancy) {
      const dateStart = new Date(vacancy.publicationDate);
      const dateFinal = vacancy.candidacies && vacancy.candidacies.length > 0 && vacancy.candidacies[0].specialDate ? new Date(vacancy.candidacies[0].specialDate) : new Date(vacancy.finalDate);
      const betwen = dateFinal - dateStart;
      var now = new Date().getTime();
      var distance = now - dateStart;
      var distanceFinal = dateFinal - now;
      var days = Math.floor(distance / (1000 * 60 * 60 * 24));
      var daysB = Math.floor(betwen / (1000 * 60 * 60 * 24));

      let result = daysB - days;

      if (distanceFinal <= 0) {
        return { status: this.$t("expired"), color: "error" };
      } else {
        return { status: '-'+ result+' '+this.$t('days'), color: "primary" };
      }
    }
  }
}