import gql from 'graphql-tag'

export const SOLVE_TEST_MUTATION = gql`
  mutation SolveTest($testId: ID!, $questions: [QuestionInput], $result: Float) {
    solveTest(testId: $testId, questions: $questions, result: $result) {
      id
      date
      duraction
      entity {
        id
        name
      }
      questions {
        answer
        question
        objective
        implication
        alternatives {
          answer
          value
        }
        totalCorrect
        percentage
        picture
      }
      status
      time
      vacancy {
        id
        title
      }
    }
  }
`

export const SEND_STAGE_TEST_MUTATION = gql`
  mutation sendStageTests($candidate: CandidateInput, $tests: [TestInput], $result: Float, $vacancyId: ID, $entity: ID,$files: [Upload]) {
    sendStageTests(candidate: $candidate, tests: $tests, result: $result, vacancyId:$vacancyId, entity:$entity, files: $files) {
      candidacies{
        id
      }
    }
  }
`